const _staticData = {
  records: {
    avg: {
      co2: '718',
      voc: '503',
      light: '225',
      noise: '51',
      humidity: '61',
      temperature: '23.8',
      index: {
        ieq: 'Excellent',
        productivity: '96% OCCUPANT PRODUCTIVITY LEVEL',
        brain: '40% BRAIN COGNITIVE FUNCTION INCREASE',
        sustainability: 'Very Good',
        wellness: 'Very Good',
        overall: 'A+',
      },
      text1u: 'WELLNESS',
      text1l: 'ENVIRONMENTAL',
      text2u: 'SUSTAINABILITY',
      text2la: 'ENERGY',
      text2lb: 'WATER',
      text2lc: 'RENEWABLES',
      text3u: 'INDOOR ENVIRONMENTAL QUALITY',
      text3la: 'THERMAL',
      text3lb: 'SOUND',
      text3lc: 'VISUAL',
      text3ld: 'AIR QUALITY',
      text4: 'Overall Building Index',
      text_right:
        'An Integrated Index which combines Wellness, Sustainability & Indoor Environmental Quality (IEQ)',
      frame3_title: 'INDOOR AIR QUALITY',
      screen3a_title: 'LIVE INDOOR CONDITIONS',
      screen3b_title: 'LIVE OUTDOOR CONDITIONS',
    },
    external_weather: {
      coord: {
        lon: 103.8501,
        lat: 1.2897,
      },
      weather: [
        {
          id: 803,
          main: 'Clouds',
          description: 'broken clouds',
          icon: '04d',
        },
      ],
      base: 'stations',
      main: {
        temp: 32.79,
        feels_like: 37.03,
        temp_min: 31.72,
        temp_max: 34.08,
        pressure: 1007,
        humidity: 54,
      },
      visibility: 10000,
      wind: {
        speed: 4.12,
        deg: 210,
      },
      clouds: {
        all: 75,
      },
      dt: 1652863005,
      sys: {
        type: 1,
        id: 9470,
        country: 'SG',
        sunrise: 1652828147,
        sunset: 1652871989,
      },
      timezone: 28800,
      id: 1880252,
      name: 'Singapore',
      cod: 200,
    },
    carbon_equivalencies: {
      carbonMetricTonnes: '92000kg CO2 EMISSIONS SAVED IN THE LAST 12 MONTHS',
      vehiclesDriven: 'EQUIVALENT TO 7847 CARS GREENHOUSE EMISSION  FOR A DAY',
      gasolineConsumed: '11200L FUEL SAVED',
      tonsWasteRecycled: '34000kg WASTE BURNT',
      USForestsPresFromConv: '2725m² FOREST PRESERVED',
      sidebar_values: {
        tonsWasteRecycled: '160 OF 1400 TREES CONSERVED',
        treeSeedlingsGrown:
          '3000 KG OF 25,000 KG EQUIVALENT WASTE RECYCLED Via Greenhouse Gas Emission Reduction',
        equivalent_households:
          '833 OF 7300 EQUIVALENT HOUSEHOLDS POWERED FOR A DAX',
        text_goals: '2022 ENVIRONMENTAL GOALS',
        last_text: 'Via Energy Efficiency Improvement',
      },
    },
    'bms-summary': {
      month_start: '2022-01-01',
      month_end: '2022-01-31',
      energy_tenanted: '168549.01',
      energy_common: '220765.99',
      total_energy: '389315',
      potable_water: '92.5',
      new_water: '1256.7',
      pv_solar: '12.5',
      total_water: '1249.2',
      energy_last_12_months: 4610407.5,
      renewable_energy_last_12_months:
        '129000kWh RENEWABLE ENERGY HARVESTED IN THE LAST 12 MONTHS',
      eui: '137kWh/m2 ENERGY UTILIZATION INDEX(AS PER BCA) IN THE LAST 12 MONTHS',
      topvalue_eui: '25 PERCENTILE OF SINGAPORE BUILDINGS',
      water_last_12_months: 17712.9,
      wei: '0.5m3/m2 WATER EFFICIENCY INDEX (AS PER PUB) IN THE LAST 12 MONTHS',
      topvalue_wui: '25',
    },
  },
}

const _donutValues = ['', 'Very Poor', 'Poor', 'Good', 'Very Good', 'Excellent']

const jq22 = $.noConflict()
jq22(function () {
  const $ = jq22
  const $dashboard = $('.dashboard-data')

  function initSlider() {
    tns({
      autoplay: true,
      container: $dashboard.get(0),
      autoplayButtonOutput: false,
      nav: false,
      speed: 500,
    })
  }

  function initData(_data) {
    const {
      records: {
        avg,
        external_weather,
        carbon_equivalencies,
        'bms-summary': bms_summary,
      },
    } = _data

    for (const [key, value] of Object.entries(avg)) {
      if (key === 'index') {
        $('.data-wellness').html(value.wellness)
        updateDonut('wellness', value.wellness)
        $('.data-sustainability').html(value.sustainability)
        updateDonut('sustainability', value.sustainability)
        $('.data-ieq').html(value.ieq)
        updateDonut('ieq', value.ieq)
        $('.data-overall').html(value.overall)
      } else {
        const $target = $(`.data-${key}`)
        if ($target.length) {
          const newValue = getTextWithUnit($target, value)
          $target.html(newValue)
        }
      }
    }

    $('.data-ext-temp').html(
      getTextWithUnit(
        $('.data-ext-temp'),
        external_weather.main.temp.toFixed(1),
      ),
    )
    $('.data-ext-weather').html(external_weather.weather[0].main)
    $('.data-ext-min-max').html(
      `${getTextWithUnit(
        $('.data-ext-min-max'),
        external_weather.main.temp_min.toFixed(1),
      )} / ${getTextWithUnit(
        $('.data-ext-min-max'),
        external_weather.main.temp_max.toFixed(1),
      )}`,
    )
    $('.data-ext-humidity').html(
      getTextWithUnit($('.data-ext-humidity'), external_weather.main.humidity),
    )
    $('.data-ext-wind').html(
      getTextWithUnit($('.data-ext-wind'), external_weather.wind.speed),
    )

    const { sidebar_values } = carbon_equivalencies
    $('.data-text_goals').html(sidebar_values.text_goals)

    const xOfYRegex = /([\d]+)\s{1,}of\s{1,}([\d]+)\s{1,}(.+)/i

    const tonsWasteRecycled = xOfYRegex.exec(sidebar_values.tonsWasteRecycled)
    if (tonsWasteRecycled.length === 4) {
      $('.data-tonsWasteRecycled-0').html(
        formatDecimalValue(tonsWasteRecycled[1]),
      )
      $('.data-tonsWasteRecycled-1').html(
        `of ${formatDecimalInString(tonsWasteRecycled[2])}<br /><span>${
          tonsWasteRecycled[3]
        }</span>`,
      )
      updateDonut(
        'tonsWasteRecycled',
        parseInt(tonsWasteRecycled[1], 10) / parseInt(tonsWasteRecycled[2], 10),
      )
    }

    const treeRegex =
      /([\d,]+)\s{1}(kg)\s{1}of\s{1,}([\d,]+)\s{1}(kg)\s{1,}(.+)\s{1,}(via.*)/i
    const treeSeedlingsGrown = treeRegex.exec(sidebar_values.treeSeedlingsGrown)
    if (treeSeedlingsGrown.length === 7) {
      $('.data-treeSeedlingsGrown-0').html(
        `${formatDecimalValue(treeSeedlingsGrown[1])}<br />${
          treeSeedlingsGrown[2]
        }`,
      )
      $('.data-treeSeedlingsGrown-1').html(
        `of ${formatDecimalValue(treeSeedlingsGrown[3].replace(',', ''))} ${
          treeSeedlingsGrown[4]
        } <span>${treeSeedlingsGrown[5]}</span>`,
      )
      $('.data-treeSeedlingsGrown-2').html(treeSeedlingsGrown[6])
      updateDonut(
        'treeSeedlingsGrown',
        parseInt(treeSeedlingsGrown[1].replace(',', ''), 10) /
          parseInt(treeSeedlingsGrown[3].replace(',', ''), 10),
      )
    }

    const equivalent_households = xOfYRegex.exec(
      sidebar_values.equivalent_households,
    )
    if (equivalent_households.length === 4) {
      $('.data-equivalent_households-0').html(
        formatDecimalValue(equivalent_households[1]),
      )
      $('.data-equivalent_households-1').html(
        `of ${formatDecimalInString(equivalent_households[2])}<br /><span>${
          equivalent_households[3]
        }</span>`,
      )
      updateDonut(
        'equivalent_households',
        parseInt(equivalent_households[1], 10) /
          parseInt(equivalent_households[2], 10),
      )
    }
    $('.data-last_text').html(sidebar_values.last_text)

    const kgOrkWhOrLRegex = /([\d]+)(kg|kWh|l)?\s{1,}(.*)/i

    const carbonMetricTonnes = kgOrkWhOrLRegex.exec(
      carbon_equivalencies.carbonMetricTonnes,
    )
    if (carbonMetricTonnes.length === 4) {
      $('.data-carbonMetricTonnes-0').html(
        `${formatDecimalValue(
          carbonMetricTonnes[1],
        )}${carbonMetricTonnes[2].replace('l', 'L')}`,
      )
      $('.data-carbonMetricTonnes-1').html(
        carbonMetricTonnes[3].replace('CO2', 'CO<sub>2</sub>'),
      )
    }

    const vehiclesDriven = /(equivalent\s{1,}.+)\s{1,}([\d]+)\s{1,}(.*)/i.exec(
      carbon_equivalencies.vehiclesDriven,
    )
    if (vehiclesDriven.length === 4) {
      $('.data-vehiclesDriven-0').html(vehiclesDriven[1])
      $('.data-vehiclesDriven-1').html(formatDecimalValue(vehiclesDriven[2]))
      $('.data-vehiclesDriven-2').html(vehiclesDriven[3])
    }

    const tonsWasteRecycled2 = kgOrkWhOrLRegex.exec(
      carbon_equivalencies.tonsWasteRecycled,
    )
    if (tonsWasteRecycled2.length === 4) {
      $('.data-tonsWasteRecycled2-0').html(
        `${formatDecimalValue(
          tonsWasteRecycled2[1],
        )}${tonsWasteRecycled2[2].replace('l', 'L')}`,
      )
      $('.data-tonsWasteRecycled2-1').html(tonsWasteRecycled2[3])
    }

    const eui = /([\w\/]+)(m[\d]{1})+\s{1,}(.+)/i.exec(bms_summary.eui)
    if (eui.length === 4) {
      $('.data-eui-0').html(`${eui[1]}${replaceSuperscript(eui[2])}`)
      $('.data-eui-1').html(eui[3])
    }

    const topvalue_eui = /([\d]+)\s{1,}(.*)/i.exec(bms_summary.topvalue_eui)
    if (topvalue_eui.length === 3) {
      $('.data-topvalue_eui-0').html(topvalue_eui[1])
      $('.data-topvalue_eui-1').html(topvalue_eui[2])
    }

    const renewable_energy_last_12_months = kgOrkWhOrLRegex.exec(
      bms_summary.renewable_energy_last_12_months,
    )
    if (renewable_energy_last_12_months.length === 4) {
      $('.data-renewable_energy_last_12_months-0').html(
        `${formatDecimalValue(renewable_energy_last_12_months[1])}${
          renewable_energy_last_12_months[2]
        }`,
      )
      $('.data-renewable_energy_last_12_months-1').html(
        renewable_energy_last_12_months[3],
      )
    }

    const gasolineConsumed = kgOrkWhOrLRegex.exec(
      carbon_equivalencies.gasolineConsumed,
    )
    if (gasolineConsumed.length === 4) {
      $('.data-gasolineConsumed-0').html(
        `${formatDecimalValue(gasolineConsumed[1])}${gasolineConsumed[2]}`,
      )
      $('.data-gasolineConsumed-1').html(gasolineConsumed[3])
    }

    const usForestsPresFromConv = /([\d]+)([M\p{No}]+)\s{1,}(.+)/iu.exec(
      carbon_equivalencies.USForestsPresFromConv,
    )
    if (usForestsPresFromConv.length === 4) {
      $('.data-USForestsPresFromConv-0').html(
        `${formatDecimalValue(usForestsPresFromConv[1])}${
          usForestsPresFromConv[2]
        }`,
      )
      $('.data-USForestsPresFromConv-1').html(usForestsPresFromConv[3])
    }

    const wei = /([\d\.]+)(m[\d]{1})\/(m[\d]{1})\s{1,}(.*)/i.exec(
      bms_summary.wei,
    )
    if (wei.length === 5) {
      $('.data-wei-0').html(
        `${wei[1]}${replaceSuperscript(wei[2])}/${replaceSuperscript(wei[3])}`,
      )
      $('.data-wei-1').html(wei[4])
      $('.data-topvalue_wui').html(bms_summary.topvalue_wui)
    }

    const productivity = /([\d]+%)\s{1,}(.*)/i.exec(avg.index.productivity)
    if (productivity.length === 3) {
      $('.data-productivity-0').html(productivity[1])
      $('.data-productivity-1').html(productivity[2])
    }

    const brain = /([\d]+%)\s{1,}(.*)/i.exec(avg.index.brain)
    if (brain.length === 3) {
      $('.data-brain-0').html(brain[1])
      $('.data-brain-1').html(brain[2])
    }
  }

  function getTextWithUnit($target, value) {
    const unit = $target.data('unit')
    return unit ? `${value}${unit}` : value
  }

  function formatDecimalValue(value) {
    return parseInt(value, 10).toLocaleString()
  }

  function replaceInlineDecimal(match) {
    return formatDecimalValue(match)
  }

  function formatDecimalInString(string) {
    return string.replace(/[\d]+/, replaceInlineDecimal)
  }

  function replaceSuperscript(string) {
    const parts = string.split('')
    return `${parts[0]}<sup>${parts[1]}</sup>`
  }

  function getDonutFixedValue(donutValue) {
    const valueIndex = _donutValues.findIndex(value => value === donutValue)
    if (valueIndex > -1) {
      return parseInt(valueIndex, 10) * 20
    } else {
      return 0
    }
  }

  function getDonutPercentageValue(donutValue) {
    return donutValue * 100
  }

  function updateDonut(key, value) {
    const newValue = isNaN(value)
      ? getDonutFixedValue(value)
      : getDonutPercentageValue(value)
    $(`#data-${key}-donut`)
      .find('.donut-segment')
      .get(0)
      .setAttribute('stroke-dasharray', `${newValue} ${100 - newValue}`)
  }

  initSlider()

  $.ajax({
    url: 'https://sensing.sg/restapi-ag/restapi/ASIAGREEN/get-data',
    headers: {
      'X-API-KEY': 'R35TAP1A51AGR33N!4832',
    },
    success: function (data) {
      console.log('Latest data loaded')
      initData(data)
    },
  }).fail(function () {
    console.error('Failed to load latest data. Loading last received instead.')
    if (window.location.hostname.includes('127.0')) {
      initData(_staticData)
    }
  })
})
